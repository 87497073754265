import React, { useState, useEffect, useRef } from "react";
import { Table, Card, Space } from "lib";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import {
  Button,
  Form,
  Input,
  TablePaginationConfig,
  DatePicker,
  Descriptions,
  Row,
  Col,
  Checkbox,
  message,
} from "antd";
import { navigate } from "gatsby";
import { Download, showError } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import { Layout } from "layout";
import BillingMenu from "@components/Admin/Billing/BillingAssessmentSummaryMenu";
import GeneralFormModal from "@components/GeneralFormModal";
import { LeftOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
import {
  BillingInformationsClient,
  PaginatedListOfAssessmentSummaryDto,
  AssessmentSummaryDto,
  AssessmentDto,
} from "@api";
import { URL_PARAMETER } from "./summary";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment from "moment";

const defaultOrderBy = "clientUserUserName";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};
const name = "Billing";

let selectedClientGroupId = -1;
let selectedAssessmentTemplateId = -1;

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function isNumeric(str: string) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

interface Params {
  search: string;
}
interface Props {
  location: Params;
}

const Page = ({ location }: Props) => {
  const auth = new SubpageAuth(SECTION.Admin, name, name);
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  if (
    location == undefined ||
    location.search == undefined ||
    location.search.length < 2
  ) {
    navigate("/400/");
    return <></>;
  }

  let searchString = location.search.substring(1);
  let searchParamArray = searchString.split("&");
  let defaultHasCompletedProgress = true;
  let defaultUserAddedTimeStartDate: Date | undefined;
  let defaultUserAddedTimeEndDate: Date | undefined;
  let defaultStartTimeStartDate: Date | undefined;
  let defaultStartTimeEndDate: Date | undefined;
  let defaultEndTimeStartDate: Date | undefined;
  let defaultEndTimeEndDate: Date | undefined;

  searchParamArray.map((value: string) => {
    let paramArray = value.split("=");
    if (
      paramArray[0] == URL_PARAMETER.CLIENT_GROUP_ID &&
      isNumeric(paramArray[1])
    ) {
      selectedClientGroupId = parseInt(paramArray[1]);
    } else if (
      paramArray[0] == URL_PARAMETER.ASSESSMENT_TEMPLATE_ID &&
      isNumeric(paramArray[1])
    ) {
      selectedAssessmentTemplateId = parseInt(paramArray[1]);
    } else if (paramArray[0] == URL_PARAMETER.PROGRESS) {
      let isTrueSet = paramArray[1].toLowerCase() === "true";
      if (isTrueSet) {
        defaultHasCompletedProgress = true;
      } else {
        let isFalseSet = paramArray[1].toLowerCase() === "false";
        if (isFalseSet) {
          defaultHasCompletedProgress = false;
        }
      }
    } else if (
      paramArray[0] == URL_PARAMETER.USER_ADDED_TIME_START_DATE &&
      isNumeric(paramArray[1])
    ) {
      defaultUserAddedTimeStartDate = new Date(parseInt(paramArray[1]));
    } else if (
      paramArray[0] == URL_PARAMETER.USER_ADDED_TIME_END_DATE &&
      isNumeric(paramArray[1])
    ) {
      defaultUserAddedTimeEndDate = new Date(parseInt(paramArray[1]));
    } else if (
      paramArray[0] == URL_PARAMETER.START_TIME_START_DATE &&
      isNumeric(paramArray[1])
    ) {
      defaultStartTimeStartDate = new Date(parseInt(paramArray[1]));
    } else if (
      paramArray[0] == URL_PARAMETER.START_TIME_END_DATE &&
      isNumeric(paramArray[1])
    ) {
      defaultStartTimeEndDate = new Date(parseInt(paramArray[1]));
    } else if (
      paramArray[0] == URL_PARAMETER.END_TIME_START_DATE &&
      isNumeric(paramArray[1])
    ) {
      defaultEndTimeStartDate = new Date(parseInt(paramArray[1]));
    } else if (
      paramArray[0] == URL_PARAMETER.END_TIME_END_DATE &&
      isNumeric(paramArray[1])
    ) {
      defaultEndTimeEndDate = new Date(parseInt(paramArray[1]));
    }
  });

  if (selectedClientGroupId == -1 || selectedAssessmentTemplateId == -1) {
    navigate("/400/");
    return <></>;
  }

  const [form] = Form.useForm();
  const [messageApi, messageContextHolder] = message.useMessage();
  const isMounted = useRef(false);
  const [downloadPaginationVisible, setDownloadPaginationVisible] =
    useState(false);

  const billingInformationClient = new BillingInformationsClient();
  const [state, setState] = useState({
    pagination: defaultPagination,
    table: {
      sortField: defaultOrderBy,
      sortOrder: defaultSortOrder,
    },
    loading: true,
    assessmentTemplateSummaryInfo: {} as AssessmentSummaryDto,
    tableContent: [] as PaginatedListOfAssessmentSummaryDto[],
  });

  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  const [filter, setFilter] = useState({
    isProgessComplete: defaultHasCompletedProgress as boolean | undefined,
    userAddedTimeStartDate: defaultUserAddedTimeStartDate as Date | undefined,
    userAddedTimeEndDate: defaultUserAddedTimeEndDate as
      | Date
      | null
      | undefined,
    startTimeStartDate: defaultStartTimeStartDate as Date | null | undefined,
    startTimeEndDate: defaultStartTimeEndDate as Date | null | undefined,
    endTimeStartDate: defaultEndTimeStartDate as Date | null | undefined,
    endTimeEndDate: defaultEndTimeEndDate as Date | null | undefined,
  });

  function getAssessmentInformation() {
    return billingInformationClient.getBillingInformationAssessmentSummaryQuery(
      keywords,
      selectedClientGroupId, // clientGroupId
      selectedAssessmentTemplateId, // assessmentTemplateId
      filter.isProgessComplete ? true : null, // isProgessComplete
      filter.userAddedTimeStartDate, // userAddedTimeStartDate,
      filter.userAddedTimeEndDate, // userAddedTimeEndDate,
      filter.startTimeStartDate, // startTimeStartDate,
      filter.startTimeEndDate, // startTimeEndDate,
      filter.endTimeStartDate, // endTimeStartDate,
      filter.endTimeEndDate // endTimeEndDate,
    );
  }

  function getAssessementDetailBillingInfoTableContent(
    sortField: string,
    sortOrder: string,
    pagination: TablePaginationConfig
  ) {
    return billingInformationClient.getBillingInformationAssessmentWithPaginationQuery(
      keywords,
      selectedClientGroupId, // clientGroupId
      selectedAssessmentTemplateId, // assessmentTemplateId
      filter.isProgessComplete ? true : null, // isProgessComplete
      filter.userAddedTimeStartDate, // userAddedTimeStartDate,
      filter.userAddedTimeEndDate, // userAddedTimeEndDate,
      filter.startTimeStartDate, // startTimeStartDate,
      filter.startTimeEndDate, // startTimeEndDate,
      filter.endTimeStartDate, // endTimeStartDate,
      filter.endTimeEndDate, // endTimeEndDate,
      pagination.current, // pageNumber,
      pagination.pageSize, // pageSize,
      capitalizeFirstLetter(sortField), // orderBy
      sortOrder == "ascend" // isOrderByAsc
    );
  }

  useEffect(() => {
    fetchItem(state.table.sortField, state.table.sortOrder, state.pagination);
  }, []);

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    let sortField =
      Object.keys(sorter).length == 0 ? defaultOrderBy : sorter.field;
    let sortOrder =
      Object.keys(sorter).length == 0 ? defaultSortOrder : sorter.order;
    fetchItem(sortField, sortOrder, pagination);
  }

  function fetchItemForDowndropChange() {
    fetchItem(state.table.sortField, state.table.sortOrder, defaultPagination);
  }

  async function fetchItem(
    sortField: string,
    sortOrder: string,
    pagination: TablePaginationConfig
  ) {
    setState({
      ...state,
      loading: true,
    });
    try {
      Promise.all([
        getAssessmentInformation(),
        getAssessementDetailBillingInfoTableContent(
          sortField,
          sortOrder,
          pagination
        ),
      ]).then((values) => {
        setState({
          ...state,
          assessmentTemplateSummaryInfo:
            (values[0] as AssessmentSummaryDto) || ({} as AssessmentSummaryDto),
          tableContent:
            (values[1].items as AssessmentDto[]) || ([] as AssessmentDto[]),
          pagination: {
            ...state.pagination,
            ...pagination,
            total: values[1].totalCount,
          },
          loading: false,
        });
      });
    } catch (err) {
      setState({
        ...state,
        loading: false,
      });
      showError(err);
    }
  }

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
  }
  
  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const StaticStartDate = formatMessage({ id: "StaticStartDate" });
  const StaticEndDate = formatMessage({ id: "StaticEndDate" });

  const columns = [
    {
      // title: "Login ID",
      title: <FormattedMessage id="BillingAssessmentDetailTableHeaderLoginID" />,
      dataIndex: "clientUserUserName",
      key: "clientUserUserName",
      sorter: true,
    },
    {
      // title: "Full Name",
      title: <FormattedMessage id="BillingAssessmentDetailTableHeaderFullName" />,
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      // title: "Email",
      title: <FormattedMessage id="BillingAssessmentDetailTableHeaderEmail" />,
      dataIndex: "clientUserEmail",
      key: "clientUserEmail",
      sorter: true,
    },
    {
      // title: "Assessment Progress",
      title: <FormattedMessage id="BillingAssessmentDetailTableHeaderAssessmentProgress" />,
      dataIndex: "progress",
      key: "progress",
      sorter: true,
      render: (d: Number) => <>{d + "%"}</>,
    },
    {
      // title: "User Added Time",
      title: <FormattedMessage id="BillingAssessmentDetailTableHeaderUserAddedTime" />,
      dataIndex: "clientUserCreated",
      key: "clientUserCreated",
      sorter: true,
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
    {
      // title: "Number of Login Times",
      title: <FormattedMessage id="BillingAssessmentDetailTableHeaderLoginTimeCount" />,
      dataIndex: "loginTimeCount",
      key: "loginTimeCount",
      sorter: false,
    },
    {
      // title: "Have Generate Report",
      title: <FormattedMessage id="BillingAssessmentDetailTableHeaderHaveGenerateReport" />,
      dataIndex: "isSuccessGenerateBefore",
      key: "isSuccessGenerateBefore",
      sorter: true,
      render: (d: Boolean) => <>{d ? "True" : "False"}</>,
    },
  ];

  const keywordElement = (
    <Form
      form={form}
      name="horizontal_login"
      layout="inline"
      onFinish={handleKeywordsSearch}
    >
      <Form.Item name="keywords">
        <Input placeholder={StaticKeywords} disabled={state.loading} />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button type="primary" htmlType="submit" disabled={state.loading}>
            <FormattedMessage id="StaticSearchLabel" />
          </Button>
        )}
      </Form.Item>
    </Form>
  );

  const handleChangeIsProgessComplete = (e: CheckboxChangeEvent) => {
    setFilter((prev) => ({ ...prev, isProgessComplete: e.target?.checked }));
  };

  const handleChangeUserAddedDate = (values: any) => {
    if (values == null) {
      setFilter((prev) => ({
        ...prev,
        userAddedTimeStartDate: undefined,
        userAddedTimeEndDate: undefined,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        userAddedTimeStartDate: values[0],
        userAddedTimeEndDate: values[1],
      }));
    }
  };

  const handleChangeStartDate = (values: any) => {
    if (values == null) {
      setFilter((prev) => ({
        ...prev,
        startTimeStartDate: undefined,
        startTimeEndDate: undefined,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        startTimeStartDate: values[0],
        startTimeEndDate: values[1],
      }));
    }
  };

  const handleChangeEndDate = (values: any) => {
    if (values == null) {
      setFilter((prev) => ({
        ...prev,
        endTimeStartDate: undefined,
        endTimeEndDate: undefined,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        endTimeStartDate: values[0],
        endTimeEndDate: values[1],
      }));
    }
  };

  const handleDownloadExcel = async (data: any) => {
    setDownloadPaginationVisible(false);
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );

    setState({ ...state, loading: true });

    try {
      const res =
        await billingInformationClient.exportBillingInformationAssessment(
          keywords,
          selectedClientGroupId, // clientGroupId
          selectedAssessmentTemplateId, // assessmentTemplateId
          filter.isProgessComplete ? true : null, // isProgessComplete
          filter.userAddedTimeStartDate, // userAddedTimeStartDate,
          filter.userAddedTimeEndDate, // userAddedTimeEndDate,
          filter.startTimeStartDate, // startTimeStartDate,
          filter.startTimeEndDate, // startTimeEndDate,
          filter.endTimeStartDate, // endTimeStartDate,
          filter.endTimeEndDate, // endTimeEndDate,
          data.pageNumber, // pageNumber,
          data.pageSize, // pageSize,
          capitalizeFirstLetter(state.table.sortField), // orderBy
          state.table.sortOrder == "ascend" // isOrderByAsc
        );
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      fetchItemForDowndropChange();
    } else {
      isMounted.current = true;
    }
  }, [filter, keywords]);

  let queryLabelSpan = 8;
  let queryDataSpan = 16;

  return (
    <Layout title="BillingAssessmentSummaryNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <BillingMenu />
          <Row justify="space-between">
            <Col xs={24} sm={12}>
              <Space justify="flex-start">
                <Col>
                  <Button
                    ghost
                    type="primary"
                    shape="round"
                    onClick={() => {
                      let url = "/admin/billing/assessment/summary";
                      navigate(url);
                    }}
                  >
                    <LeftOutlined />
                  </Button>
                </Col>
                <Col>
                  <Space justify="flex-start">{keywordElement}</Space>
                </Col>
              </Space>
            </Col>
            <Col xs={24} sm={12}>
              <Row justify="end">
                <Button
                  ghost
                  type="primary"
                  shape="round"
                  onClick={() => {
                    navigate(`/admin/billing/report/summary`, {
                      replace: false,
                    });
                  }}
                >
                  <FormattedMessage id="BillingReportSummaryNavigationTitle" />{" "}
                </Button>
              </Row>
            </Col>
          </Row>
          {Object.keys(state.assessmentTemplateSummaryInfo).length > 0 ? (
            <Space justify="flex-start">
              <Descriptions
                contentStyle={{ width: "250px" }}
                column={{ sm: 1, md: 3 }}
                size="small"
                bordered
              >
                <Descriptions.Item
                  label={
                    <FormattedMessage id="BillingAssessmentDetailContentAssessmentLabel" />
                  }
                >
                  {state.assessmentTemplateSummaryInfo?.assessment ?? (
                    <FormattedMessage id="StaticNaLabel" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage id="BillingAssessmentDetailContentCompleteAssessmentLabel" />
                  }
                >
                  {state.assessmentTemplateSummaryInfo
                    ?.completedAssessmentCount ?? (
                    <FormattedMessage id="StaticNaLabel" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage id="BillingAssessmentDetailContentTotalAssessmentLabel" />
                  }
                >
                  {state.assessmentTemplateSummaryInfo
                    ?.totalAssessmentCount ?? (
                    <FormattedMessage id="StaticNaLabel" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage id="BillingAssessmentDetailContentParentGroupLabel" />
                  }
                >
                  {state.assessmentTemplateSummaryInfo?.parentGroup ?? (
                    <FormattedMessage id="StaticNaLabel" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage id="BillingAssessmentDetailContentGroupLabel" />
                  }
                  span={3}
                >
                  {state.assessmentTemplateSummaryInfo?.group ?? (
                    <FormattedMessage id="StaticNaLabel" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage id="BillingAssessmentDetailContentActivateAccLabel" />
                  }
                >
                  {state.assessmentTemplateSummaryInfo?.activateAccount ?? (
                    <FormattedMessage id="StaticNaLabel" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage id="BillingAssessmentDetailContentDeactivateAccabel" />
                  }
                >
                  {state.assessmentTemplateSummaryInfo?.deactivateAccount ?? (
                    <FormattedMessage id="StaticNaLabel" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage id="BillingAssessmentDetailContentTotalAccLabel" />
                  }
                >
                  {state.assessmentTemplateSummaryInfo?.totalAccount ?? (
                    <FormattedMessage id="StaticNaLabel" />
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Space>
          ) : null}
          <Row justify="space-between" align="bottom">
            <Col>
              <Row gutter={[16, 8]} style={{ alignItems: "center" }}>
                <Col span={queryLabelSpan}><FormattedMessage id="BillingAssessmentDetailFilterLabeluserAddedDate" />:</Col>
                <Col span={queryDataSpan}>
                  <RangePicker
                    placeholder={[StaticStartDate, StaticEndDate]}
                    defaultValue={[
                      filter.userAddedTimeStartDate == null
                        ? null
                        : moment(filter.userAddedTimeStartDate),
                      filter.userAddedTimeEndDate == null
                        ? null
                        : moment(filter.userAddedTimeEndDate),
                    ]}
                    showTime
                    allowEmpty={[true, true]}
                    onChange={handleChangeUserAddedDate}
                    disabled={state.loading}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 8]} style={{ alignItems: "center" }}>
                <Col span={queryLabelSpan}><FormattedMessage id="BillingAssessmentDetailFilterLabelAssessmentStartDate" />::</Col>
                <Col span={queryDataSpan}>
                  <RangePicker
                    placeholder={[StaticStartDate, StaticEndDate]}
                    defaultValue={[
                      filter.startTimeStartDate == null
                        ? null
                        : moment(filter.startTimeStartDate),
                      filter.startTimeEndDate == null
                        ? null
                        : moment(filter.startTimeEndDate),
                    ]}
                    showTime
                    allowEmpty={[true, true]}
                    onChange={handleChangeStartDate}
                    disabled={state.loading}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 8]} style={{ alignItems: "center" }}>
                <Col span={queryLabelSpan}><FormattedMessage id="BillingAssessmentDetailFilterLabelAssessmentEndDate" />::</Col>
                <Col span={queryDataSpan}>
                  <RangePicker
                    placeholder={[StaticStartDate, StaticEndDate]}
                    defaultValue={[
                      filter.endTimeStartDate == null
                        ? null
                        : moment(filter.endTimeStartDate),
                      filter.endTimeStartDate == null
                        ? null
                        : moment(filter.endTimeStartDate),
                    ]}
                    showTime
                    allowEmpty={[true, true]}
                    onChange={handleChangeEndDate}
                    disabled={state.loading}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 8]} style={{ alignItems: "center" }}>
                <Col span={queryLabelSpan}><FormattedMessage id="BillingAssessmentDetailFilterLabelCompletedAssessment" />::</Col>
                <Col span={queryDataSpan}>
                  <Checkbox
                    onChange={handleChangeIsProgessComplete}
                    defaultChecked={filter.isProgessComplete}
                    disabled={state.loading}
                  ></Checkbox>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => setDownloadPaginationVisible(true)}
                disabled={state.loading}
              >
                <Space>
                  <FormattedMessage id="StaticExportTableButton" />
                </Space>
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            dataSource={[...state.tableContent]}
            columns={columns}
            rowKey={(r) => r.clientUserId}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      <GeneralFormModal
        visible={downloadPaginationVisible}
        handleAction={handleDownloadExcel}
        handleCancelAction={() => setDownloadPaginationVisible(false)}
        titleId={"BillingAssessmentDetailModalExportTableTitle"}
        okTextId={"StaticDownloadButton"}
        editableFields={[
          {
            name: "pageSize",
            labelId: "StaticPageSizeLabel",
            type: "number",
            rules: [
              {
                required: true,
                message: <FormattedMessage id="StaticMissingPageSizeRule" />,
              },
            ],
          },
          {
            name: "pageNumber",
            labelId: "StaticPageNumberLabel",
            type: "number",
            rules: [
              {
                required: true,
                message: <FormattedMessage id="StaticMissingPageNumberRule" />,
              },
            ],
          },
        ]}
      />
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
